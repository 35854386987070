<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <div class="heading">
            Other Student Pages
            <div class="hLine"></div>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row>
        <v-col cols="12" sm="8">
          <div>
            <v-row>
              <v-col
                cols="12"
                sm="5"
                :class="$vuetify.breakpoint.smAndUp?'px-6':'px-6'"
                v-for="(item, i) in studentList"
                :key="i"
              >
                <div class="studentDetailCard" @click="selectStudent(item.id)">
                  <v-card style="border-radius: 12px" min-height="120px">
                    <v-card-text class="px-0">
                      <div class="d-flex justify-center">
                        <div v-if="item.profile_pic">
                          <img
                            :src="item.profile_pic"
                            width="80"
                            alt=""
                            style="border-radius: 15px"
                          />
                        </div>
                        <div v-else>
                          <img
                            src="@/assets/publicDonationPage/student.png"
                            width="80"
                            style="border-radius: 15px"
                          />
                        </div>
                        <div class="d-flex justify-center ml-2">
                          <div style="margin: auto; font-size: 18px">
                            <div
                              style="
                                font-size: 22px;
                                font-weight: 700;
                                color: #2c1963;
                              "
                            >
                              {{ item.student_first_name }}
                              {{ item.student_last_name }}
                            </div>
                            <div>{{ item.grade_teacher }}</div>
                            <div>(Grade {{ item.grade_title }})</div>
                          </div>
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </div>
              </v-col>
              <v-col cols="12" sm="5" class="px-6 pt-4">
                <div
                  class="createStudentBtn d-flex justify-center"
                  @click="
                    toggleCreateStudentModal({ show: true, actionType: 'add' })
                  "
                >
                  <div style="margin: auto">
                    <img
                      src="@/assets/publicDonationPage/createStudent/galleryPlus.svg"
                      width="38"
                      alt=""
                    />
                  </div>
                  <div style="margin: auto" class="ml-3 d-flex justify-center">
                    Create Student Page
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
      <br /><br /><br />
      <v-row>
        <v-col cols="12" sm="8" style="border-top: 2px dashed #757575" class="py-10">
          <v-row>
            <v-col cols="3">
              <img
                :src="schoolDetail.logo_url"
                :width="$vuetify.breakpoint.smAndUp ? 140 : 70"
                alt=""
              />
            </v-col>
            <v-col
              cols="8"
              :class="$vuetify.breakpoint.smAndUp ? 'px-0 pt-12' : ''"
            >
              <span class="headingSchool">{{ schoolDetail.school_name }}</span>
            </v-col>
            <v-col cols="12" sm="10">
              <div style="font-size: 24px; color: #2c1963"
              v-if="schoolDetail.original_total_fund_raised">
                 ${{ schoolDetail.original_total_fund_raised_50.toLocaleString("en-US") }}
                <span
                  style="
                    color: #2d2d2d;
                    font-size: 16px;
                    margin-top: auto;
                    margin-bottom: auto;
                  "
                  v-if="schoolDetail.school_goal"
                  >raised of ${{
              schoolDetail.school_goal_50.toLocaleString("en-US")
            }} School goal</span
                >
              </div>
              <v-progress-linear
                :value="schoolDetail.original_fund_raise_percentage"
                color="#4A3290"
                height="25"
                style="border-radius: 20px"
              >
                <strong style="color: white"
                  >{{ schoolDetail.original_fund_raise_percentage }}%</strong
                >
              </v-progress-linear>
              <div style="font-size: 20px; width: 100%"
              v-if="schoolDetail.total_donor">
                {{ schoolDetail.total_donor.toLocaleString("en-US") }}
                <span style="font-size: 16px; color: #757575">donors</span>
                <div style="float: right">
                  {{ getCampaign.campaign_detail.remaining_day }}
                  <span style="font-size: 16px; color: #757575">days left</span>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import {
  API_PDP_STUDENT_LIST,
  API_ADMIN_GET_SCHOOL_DETAIL,
} from "@/constants/APIUrls";
import Axios from "@/api/BaseAxios";
import { ID } from "@/constants/APIKeys";
import { STUDENT } from "@/constants/ModuleKeys";
import { ROUTER_URL } from "@/constants/urls";
export default {
  name: "StudentCards",
  data() {
    return {
      studentList: [],
      schoolDetail: {},
      currentStudent: null,
    };
  },
  created() {
    this.$root.$refs.studentCard = this;
  },
  computed: {
    ...mapGetters({
      getCampaign: "publicDonationPage/getCampaign",
      userDetails: "publicDonationPage/getUserDetails",
    }),
    getCampaignDetail() {
      return this.getCampaign;
    },
    schoolFundRaisedPercentage() {
      return Math.round(
        (this.schoolDetail.total_fund_raised / this.schoolDetail.school_goal) *
          100
      );
    },
  },
  watch: {},
  methods: {
    ...mapActions({
      toggleCreateStudentModal: "publicDonationPage/toggleCreateStudentModal",
      updateSelectedStudent: "publicDonationPage/updateSelectedStudent",
    }),
    selectStudent(id) {
      let student = this.studentList.find((student) => student.id === id);
      this.updateSelectedStudent({ student });
      this.$router.push({
        name: ROUTER_URL.publicParentDashboard.name,
        query: { ...this.$route.query, [STUDENT]: student[ID] },
      });
      this.getStudentListData();
    },
    filterStudent(list) {
      // console.log(list);
      this.studentList = list;
      console.log(this.studentList);
      let index = this.studentList.findIndex(
        (item) => item.id == this.currentStudent
      );
      this.studentList.splice(index, 1);
    },
    getStudentListData() {
      const _this = this;
      _this.loading = true;
      const successHandler = (res) => {
        // _this.studentList = res.data.student_list;
        this.filterStudent(res.data.student_list);
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      const finallyHandler = () => {
        this.getSchoolDetail();
      };
      let formData = {};
      return Axios.request_GET(
        API_PDP_STUDENT_LIST,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        finallyHandler
      );
    },
    getSchoolDetail() {
      const successHandler = (res) => {
        this.schoolDetail = res.data.school_detail;
      };
      const failureHandler = (res) => {
        console.log(res);
      };
      let formData = {};
      formData["school_id"] = this.getCampaignDetail.student_detail.school_id;
      formData["campaign_id"] = this.getCampaignDetail.campaign_detail.id;
      Axios.request_GET(
        API_ADMIN_GET_SCHOOL_DETAIL,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        false
      );
    },
  },
  mounted() {
    this.currentStudent = this.$route.query.student;
    this.getStudentListData();
  },
};
</script>
<style scoped>
* {
  font-family: Lato;
}
.heading {
  font-size: 25px;
  font-family: Roboto Slab;
  font-weight: 600;
  color: #2c1963;
  display: flex;
}
.hLine {
  background-color: #2c1963;
  width: 50px;
  height: 4px;
  border-radius: 20px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 15px;
}
.createStudentBtn {
  border: 2px dashed #2c1963;
  height: 115px;
  width: 100%;
  font-size: 22px;
  display: flex;
  padding: 20px;
  background: rgba(237, 237, 237, 0.1);
  box-sizing: border-box;
  border-radius: 14px;
  cursor: pointer;
  font-weight: 700;
}
.studentDetailCard {
  width: 100%;
  height: 120px;
  cursor: pointer;
}
.headingSchool {
  font-size: 27px;
  font-weight: 700;
  font-family: Roboto Slab;
}
@media only screen and (max-width: 600px) {
  .heading {
    font-size: 20px;
  }
  .hLine {
    background-color: #2c1963;
    width: 30px;
    height: 3px;
    margin-top: 15px;
  }
}
</style>
